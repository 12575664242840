:root {
  --app-color-brand-h: 0;
  --app-color-brand-s: 100%;
  --app-color-brand-l: 40%;
  --app-color-brand: hsl(var(--app-color-brand-h), var(--app-color-brand-s), var(--app-color-brand-l));
  --app-color-brand-highlight: hsl(var(--app-color-brand-h), calc(var(--app-color-brand-s) - 35%), calc(var(--app-color-brand-l) + 18%));
  --app-color-brand-dark-h: var(--app-color-brand-h);
  --app-color-brand-dark-s: var(--app-color-brand-s);
  --app-color-brand-dark-l: var(--app-color-brand-l);
  --app-color-brand-dark: hsl(var(--app-color-brand-dark-h), var(--app-color-brand-dark-s), var(--app-color-brand-dark-l));
  --app-color-brand-light-h: var(--app-color-brand-h);
  --app-color-brand-light-s: var(--app-color-brand-s);
  --app-color-brand-light-l: var(--app-color-brand-l);
  --app-color-brand-light: hsl(var(--app-color-brand-light-h), var(--app-color-brand-light-s), var(--app-color-brand-light-l));
  --app-color-brand-accent-h: 46;
  --app-color-brand-accent-s: 100%;
  --app-color-brand-accent-l: 59%;
  --app-color-brand-accent: hsl(var(--app-color-brand-accent-h), var(--app-color-brand-accent-s), var(--app-color-brand-accent-l));
  --app-color-brand-accent-highlight: hsl(var(--app-color-brand-accent-h), calc(var(--app-color-brand-accent-s) - 7%), calc(var(--app-color-brand-accent-l) + 15%));
  --app-color-brand-accent-dark-h: var(--app-color-brand-accent-h);
  --app-color-brand-accent-dark-s: var(--app-color-brand-accent-s);
  --app-color-brand-accent-dark-l: var(--app-color-brand-accent-l);
  --app-color-brand-accent-dark: hsl(var(--app-color-brand-accent-dark-h), var(--app-color-brand-accent-dark-s), var(--app-color-brand-accent-dark-l));
  --app-color-brand-accent-light-h: var(--app-color-brand-accent-h);
  --app-color-brand-accent-light-s: var(--app-color-brand-accent-s);
  --app-color-brand-accent-light-l: var(--app-color-brand-accent-l);
  --app-color-brand-accent-light: hsl(var(--app-color-brand-accent-light-h), var(--app-color-brand-accent-light-s), var(--app-color-brand-accent-light-l));
  --app-color-off-white: hsl(47, 44%, 94%);
  --app-text-color: hsl(calc(var(--app-color-brand-h) + 40), 5%, 31%);
  --app-color-neutral-h: var(--app-color-brand-h);
  --app-color-neutral-s: 3%;
  --app-color-neutral-white: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 100%);
  --app-color-neutral-lightest: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 95%);
  --app-color-neutral-lighter: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 89%);
  --app-color-neutral-light: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 79%);
  --app-color-neutral: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 58%);
  --app-color-neutral-dark: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 31%);
  --app-color-neutral-darker: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 22%);
  --app-color-neutral-darkest: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 15%);
  --app-color-neutral-black: hsl(var(--app-color-neutral-h), var(--app-color-neutral-s), 0%);
  --app-color-shadow-line: hsla(189, 9%, 15%, .15);
  --app-shadow-sticker: drop-shadow(1px 1px 0 var(--app-color-shadow-line)) drop-shadow(0 0 1px var(--app-color-shadow-line));
  --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);
  --app-shadow-inset-low: inset 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-inset-mid: inset 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-inset-high: inset 0 5px 10px var(--app-color-shadow-line);

  --app-background: var(--app-color-off-white);

  --app-header-height: 3em;
  --app-header-background: hsl(var(--app-color-brand-h), 0%, 100%);
  --app-header-background-focus: hsl(var(--app-color-brand-h), 10%, 90%);
  --app-header-background-active: hsl(var(--app-color-brand-h), var(--app-color-brand-s), var(--app-color-brand-l));
  --app-header-background-active-focus: hsl(var(--app-color-brand-h), var(--app-color-brand-s), calc(var(--app-color-brand-l) + 5%));
  --app-header-color: var(--app-text-color-dark);
  --app-header-color-focus: var(--app-header-color);
  --app-header-color-active: hsl(var(--app-color-brand-h), 0%, 100%);
  --app-header-color-active-focus: var(--app-header-color-active);
}


@font-face {
  font-family: texts;
  font-display: swap;
  src:  url('../.resources/fonts/interface/interface-regular.woff2') format('woff2'),
        url('../.resources/fonts/interface/interface-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src:  url('../.resources/fonts/interface/interface-bold.woff2') format('woff2'),
        url('../.resources/fonts/interface/interface-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
	text-rendering: optimizeLegibility;
}

body {
  background: var(--app-background);
}

body, input, textarea, select, button {
  font-family: texts, Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
}

h1, h2, h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  font-weight: normal;
  letter-spacing: -.03em;
  line-height: 1.1;
}

.app-logo::after {
  content: url('../.resources/svgs/logo-mycasino.svg');
  width: 6.5em;
}